<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <div class="card-header mt--4">
                    <div class="row">
                        <div class="col">
                            <h3 class="mb-0 text-center">{{ tt('mcr_detail') }}</h3>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-6">
                            <h3>{{ tt('mcr_information') }}</h3>
                        </div>
                        <div v-if="formtype == 'edit'" class="col-6 text-right">
                            <base-button size="sm" type="default" @click="editMcrInformation">{{ tt('edit') }}</base-button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <table class="table table-responsive-md table-bordered">
                                <tbody>
                                    <tr>
                                        <th class="bg-secondary">{{ tt('subject') }}</th>
                                        <td colspan="3">{{ mcrInformation.subject }}</td>
                                    </tr>
                                    <tr>
                                        <th class="bg-secondary">{{ tt('type') }}</th>
                                        <td>{{ mcrInformation.form_description }}</td>
                                        <th class="bg-secondary">{{ tt('created_by') }}</th>
                                        <td>{{ mcrInformation.created_by }}</td>
                                    </tr>
                                    <tr>
                                        <th class="bg-secondary">{{ tt('status') }}</th>
                                        <td>{{ mcrInformation.status }}</td>
                                        <th class="bg-secondary">{{ tt('created_at') }}</th>
                                        <td>{{ mcrInformation.created_at }}</td>
                                    </tr>
                                </tbody>
                            </table> 
                        </div>
                    </div>   
                    <br>
                    <div class="row">
                        <div class="col">
                            <h3>{{ tt('mcr_item') }}</h3>
                        </div>
                        <div class="col text-right">
                            <div v-if="formtype == 'view'" class="col text-right">
                              <base-button size="sm" type="default" @click="tracking()">{{
                                tt("tracking")
                              }}</base-button>
                            </div>
                            <div v-if="formtype == 'edit'" class="col text-right">
                              <base-button size="sm" type="default" @click="create('b5')">{{
                                tt("add_new")
                              }}</base-button>
                            </div>
                        </div>
                    </div>
                    <el-table class="table-responsive table-flush" header-row-class-name="thead-light" :data="mcrItem">
                        <el-table-column :label="tt('no')" :prop="tt('no')" min-width="100px" sortable>
                            <template v-slot="{row}">
                                {{ row.id }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType != 'b6'" :label="tt('material_number')" :prop="tt('material_number')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.material_number }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'a1' || mcrType == 'a2' || mcrType == 'b1' || mcrType == 'b2' || mcrType == 'b3'" :label="tt('specification')" :prop="tt('specification')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.specification }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'a3' || mcrType == 'b4'" :label="tt('note')" :prop="tt('note')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.note }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'a4'" :label="tt('reason')" :prop="tt('reason')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.reason }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'b6'" :label="tt('consolidate_from')" :prop="tt('consolidate_from')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.prc_consolidate_from }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'b6'" :label="tt('consolidate_to')" :prop="tt('consolidate_to')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.prc_consolidate_to }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="action" width="100">
                            <template v-slot="{row}">
                                <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['smartcat-attachment-update-folder'] || authUserPermission['smartcat-attachment-delete-folder'] || authUserPermission['smartcat-attachment-moveto-folder'] || authUserPermission['smartcat-attachment-update-file'] || authUserPermission['smartcat-attachment-delete-file'] || authUserPermission['smartcat-attachment-moveto-file']">
                                    <span class="btn btn-sm btn-icon-only text-light">
                                        <i class="fas fa-ellipsis-v mt-2"></i>
                                    </span>
                                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                        <el-dropdown-item v-if="formtype == 'view'" :command="{action:'detail', data:{btn:'detail', mcr_item_code:row.mcr_item_code,mcr_type:'b5'}}">{{ tt('detail') }}</el-dropdown-item>
                                        <!-- <el-dropdown-item :command="{action:'detail', data:{btn:'edit', mcr_item_code:row.mcr_item_code,mcr_type:mcrInformation.type}}">{{ tt('edit') }}</el-dropdown-item> -->
                                        <el-dropdown-item v-if="formtype == 'edit'" :command="{action:'detail', data:{btn:'edit', mcr_item_code:row.mcr_item_code,mcr_type:'b5'}}">{{ tt('edit') }}</el-dropdown-item>
                                        <el-dropdown-item v-if="formtype == 'edit'" :command="{action:'remove', data:{id:row.id}}">{{ tt('delete') }}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="card-footer pb-0 ">                           
                    <span class="float-right mt--4 mb-4">
                      Total Items {{total_mcr_item}}
                    </span>
                </div>
            </div>
            <div class="row">
                <div v-if="formtype == 'edit'" class="col text-center">
                    <router-link :to="'/material/draft-list'" class="btn btn-sm btn-dark">Draft List</router-link>  
                    <base-button size="sm" type="button" class="btn btn-lg btn-login" style="background: linear-gradient(to left,  #2e41db, #5626c7);padding-right: 40px;padding-left: 40px;color: #fff;outline-color: black;" v-on:click="sendMcr(mcr_code)" :disabled="btnSend.onLoading">
                      <span v-if="btnSend.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                      <span v-else>{{ tt('send') }}</span>
                    </base-button>
                </div>
            </div>
        </div>

        <!-- MODAL TRACKING  -->
        <tracking v-if="formTracking.show" form-show="formTracking.show" :mcr-code="mcr_code"/>
        
        <!--Form Edit Subject-->
        <validation-observer v-slot="{invalid}">
            <modal :show.sync="formMcrInformation.show">
                <template slot="header">
                    <h5 class="modal-title">{{tt('edit_subject')}}</h5>
                </template>
                <div>
                    <!-- <base-alert type="danger" v-if="errorMessage">
                        <p><strong>{{ errorMessage.message }}</strong></p>
                        <div v-if="errorMessage.data.length != 0">
                            <span v-for="message in errorMessage.data">
                                {{ message[0] }}<br>
                            </span>
                        </div>
                    </base-alert> -->
                    <label class="form-control-label">{{ tt('subject') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('subject')" :placeholder="tt('subject')" v-model="mcrInformationEdit.new_subject" rules="required"></base-input>
                </div>
                <template slot="footer">
                    <base-button type="secondary" @click="formMcrInformation.show = false">{{ tt('close') }}</base-button>
                    <base-button type="primary" v-on:click="updateMcrInformation" :disabled="btnUpdate.onLoading || invalid">
                        <span v-if="btnUpdate.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                        <span v-else>
                            {{ tt('update') }}
                        </span>
                    </base-button>
                </template>
            </modal>
        </validation-observer>
        <!--Form Edit Subject-->
        
        
        <modal :show.sync="form.b5.show" size="lg">
            <template slot="header">
                <!-- <h5 class="modal-title">{{tt('add_new')}}</h5> -->
                <h5 class="modal-title">{{form.title}}</h5>
            </template>

            <div class="card">
                <div class="card-body">
                    <h4 class="bg-primary text-white border pt-2 pl-2 pb-2 pr-2">
                        <div class="row">
                            <div class="col-md-11">
                                {{tt('material_asal')}}
                            </div>
                        </div>
                    </h4>
                    <div class="row">
                        <div class="col-md-12">
                            <base-button size="sm" class="float-right" type="default" @click="addParamsB5"><i class="fa fa-plus"></i></base-button>
                        </div>
                    </div>
                    <div v-for="(bb, key) in paramsB5.deletion_type" v-bind:key="bb">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-control-label">{{ tt('material_number') }} <span class="text-danger">*</span></label>
                                <base-input :name="tt('item_type')" rules="required" v-model="paramsB5.consolidate_from_material[key]">
                                    <el-autocomplete
                                    class="select-danger"
                                    v-model="paramsB5.consolidate_from_material[key]"
                                    :fetch-suggestions="querySearchMaterialNumber"
                                    :placeholder="tt('choose_material_number')"
                                    :trigger-on-focus="false"
                                    @select="selectMaterialNumber(key)"
                                    :disabled="form.b5.disabled"
                                    style="width: 340px;"
                                    ></el-autocomplete>
                                </base-input>
                            </div>
                            <div class="col-md-6">
                                <label class="form-control-label">{{ tt('description') }}</label>
                                <input disabled class="form-control form-control" v-model="paramsB5.description_asal[key]" :name="tt('description')" :placeholder="tt('description')" rules="required">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-control-label">{{ tt('plant') }} <span class="text-danger">*</span></label>
                                <base-input :name="tt('item_type')" rules="required" v-model="paramsB5.consolidate_from_plant[key]">
                                    <el-select :disabled="form.b5.disabled" class="select-danger" v-model="paramsB5.consolidate_from_plant[key]" :placeholder="tt('choose_plant')">
                                        <el-option class="select-danger" :value="pc.plant_code" :label="pc.plant_code +' - '+ pc.description" v-bind:key="pc.plant_code" v-for="pc in  plantMaterial"></el-option>
                                    </el-select>
<!-- 
                                    <el-autocomplete
                                    class="select-danger"
                                    v-model="paramsB5.consolidate_from_plant[key]"
                                    :fetch-suggestions="querySearchPlant"
                                    :placeholder="tt('choose_plant')"
                                    :trigger-on-focus="false"
                                    @select="selectPlant"
                                    @focus="selectPlantKey(key)"
                                    :disabled="form.b5.disabled"
                                    style="width: 340px;"
                                    ></el-autocomplete> -->
                                </base-input>
                            </div>
                            <div class="col-md-6">
                                <label class="form-control-label">{{ tt('deletion_type') }} <span class="text-danger">*</span></label>
                                <base-input :name="tt('bom_or_non_bom')" rules="required">
                                    <el-select :disabled="form.b5.disabled" class="select-danger" v-model="paramsB5.deletion_type[key]" :placeholder="tt('choose_deletion_type')">
                                        <el-option class="select-danger" :value="dt" :label="dt" :key="dt" v-for="dt in  deletionType"></el-option>
                                    </el-select>
                                </base-input>
                            </div>
                        </div>
                        <base-button v-if="key != 0" size="sm" class="float-right" type="danger" @click="removeParamsB5(key)"><i class="fa fa-minus"></i></base-button>
                        <hr>
                    </div>
                    <h4 class="bg-primary text-white border pt-2 pl-2 pb-2 pr-2">
                        <div class="row">
                            <div class="col-md-6">
                                {{tt('material_number_tujuan')}}
                            </div>
                        </div>
                    </h4>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-control-label">{{ tt('material_number') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('item_type')" rules="required">
                                <el-autocomplete
                                    class="select-danger"
                                    v-model="inputDraftListB5.prc_consolidate_to"
                                    :fetch-suggestions="querySearchMaterialNumber"
                                    :placeholder="tt('choose_material_number')"
                                    :trigger-on-focus="false"
                                    @select="selectMaterialNumber"
                                    :disabled="form.b5.disabled"
                                    style="width: 340px;"
                                    ></el-autocomplete>
                            </base-input>
                        </div>
                        <div class="col-md-6">
                            <label class="form-control-label">{{ tt('description') }}</label>
                            <input disabled class="form-control form-control" v-model="inputDraftListB5.description_to" :name="tt('description')" :placeholder="tt('description')" rules="required">
                        </div>
                    </div>
                <!--     <div class="row">
                        <div class="col-md-6">
                            <label class="form-control-label">{{ tt('plant') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('item_type')" rules="required">
                                <el-select :disabled="form.b5.disabled" v-model="inputDraftListB5.consolidate_to_plant" multiple filterable remote reserve-keyword :placeholder="tt('choose_plant')" :remote-method="getPlantCode" :loading="inputTo.loading">
                                    <el-option v-for="pc in  plantCode" :key="pc.plant_code" :label="pc.description +'-'+ pc.plant_code" :value="pc.plant_code"></el-option>
                                </el-select>
                            </base-input>
                        </div>
                    </div> -->
                    <label class="form-control-label">{{ tt('reason') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('reason')" rules="required">
                        <textarea :disabled="form.b5.disabled" class="form-control" v-model="inputDraftListB5.reason" rows="5"></textarea>
                    </base-input>
                    <label class="form-control-label">{{ tt('note') }}</label>
                    <base-input :name="tt('note')">
                        <textarea :disabled="form.b5.disabled" class="form-control" v-model="inputDraftListB5.note" rows="5"></textarea>
                    </base-input>
                </div>
            </div>
            <template slot="footer">
                <base-button type="secondary" @click="form.b5.show = false">{{ tt('close') }}</base-button>
                <base-button v-if="form.b5.type == 'add'" type="primary" @click="saveB5('add')">{{ tt('add') }}</base-button>
                <base-button v-else-if="form.b5.type == 'edit'" type="primary" @click="saveB5('edit')">{{ tt('edit') }}</base-button>
            </template>
        </modal>
        
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import draftList from '@/services/material/draftList.service';
    import materialType from '@/services/master/materialType.service';
    import plantCode from '@/services/master/plantCode.service';
    import materialNumber from '@/services/master/materialNumber.service';
    import endUserSearchMaterial from '@/services/endUserSearch/endUserSearchMaterial.service';

    export default {        
        data() {
            return { 
                formTracking: {
                    show: false
                },
                loadTimeout: null,  
                btnUpdate: {
                    onLoading: false
                },
                btnSend: {
                    onLoading: false
                },        
                formMcrInformation: {
                    show: false
                },                
                form: {
                    b5: {
                        show: false,
                        disabled: false,
                        type: 'add',
                    },
                },
                formDetail: {
                    show: false
                },  
                mcrType: 'a1',  
                inputDraftList: {

                },
                inputDraftListB5: {
                    prc_consolidate_to:'',
                    consolidate_to_plant: [],
                    description_to: '',
                    note: '',
                },
                draftList: {},
                mcrInformation: {},
                mcrInformationEdit: {
                    new_subject: ''
                },
                data: {
                    search: {
                        material_number: [],
                        plant_code: [],
                    },
                },
                mcrItem: [],
                materialType: {},
                // plantCode: [],
                materialNumber: {},
                paramsB5: {
                    consolidate_from_material: [],
                    consolidate_from_plant: [],
                    deletion_type: [],
                    description_asal: [],
                },
                mcr_code: window.location.hash.split('/')[3],
                token: window.location.hash.split('/')[4],
                formtype: window.location.hash.split("/")[5],

                materialNumber: [],
                plantCode: [],
                inputTo: {
                    loading: false,
                    loadTimeout: null
                },  
                plantKey: '',
                total_mcr_item: '',
                plantMaterial: [],
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
            this.getPlantCode()//a1,a4
            this.getMaterialNumber()//a1,a4
            this.links_material_number = this.materialNumber;
            this.links_plant_code = this.plantCode;
        },
        methods: {
            get() {
                let context = this;               
                Api(context, draftList.getMcrItem(context.mcr_code, context.token)).onSuccess(function(response) {                 
                    context.mcrItem = response.data.data.mcr_item;
                    context.mcrInformation = response.data.data.mcr[0];
                    context.parameter = response.data.data.parameter;
                    context.category = response.data.data.category;
                    context.itemType = response.data.data.item_type;
                    context.fabNonFab = response.data.data.fab_non_fab;
                    context.bomNonBom = response.data.data.bom_non_bom;
                    context.deletionType = response.data.data.deletion_type;
                    context.mcrInformationEdit.new_subject = response.data.data.mcr[0].subject;  
                    context.total_mcr_item = response.data.data.mcr_item.length;                  
                })
                .call()
            },
            getPlantCode(query) {                        
                clearTimeout(this.inputTo.loadTimeout);             
                let context = this;   
                this.inputTo.loadTimeout = setTimeout(() => {
                    this.inputTo.loading = true;
                    Api(context, plantCode.companyPlant({search: query} )).onSuccess(function(response) {
                        if (query == null ) {
                            response.data.data.forEach(function(item, index) {
                                context.plantCode[index] = {
                                    'code': item['plant_code'],
                                    'value': item['plant_code'] +' - '+ item['description'],
                                };
                            });
                        }
                        context.plantCode = response.data.data;                         
                        context.inputTo.loading = false;
                    }).onError(function(error) {
                        context.plantCode = [];
                        context.inputTo.loading = false;
                    }).call();             
                })                
            },
            getMaterialNumber() { 
                let context = this;               
                Api(context, materialNumber.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    response.data.data.forEach(function(item, index) {
                        context.materialNumber[index] = {
                            'value': item['material_number'],
                        };
                    }); 
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        materialNumber = [];
                    }
                })
                .call()
            },
            handleTableAction(command) {
                this.formTracking.show = false;
                switch (command.action) {
                    case 'detail':
                            this.detail(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            detail(data) {
                let context = this;

                if (data.mcr_type == 'MCR01') {
                    data.mcr_type = 'a1';
                } else if (data.mcr_type == 'MCR02') {
                    data.mcr_type = 'a2';
                } else if (data.mcr_type == 'MCR03') {
                    data.mcr_type = 'a3';
                } else if (data.mcr_type == 'MCR04') {
                    data.mcr_type = 'a4';
                }

                if (data.mcr_type == 'b5') {
                    if (data.btn == 'detail') {
                        context.form.b5.show = true;
                        context.form.b5.disabled = true;
                        context.form.b5.type = 'detail';
                        context.form.title = context.tt('detail');
                    } else {
                        context.form.b5.show = true;
                        context.form.b5.disabled = false;
                        context.form.b5.type = 'edit';
                        context.form.title = context.tt('edit');
                    }

                    context.inputDraftListB5.id =[];
                    context.paramsB5.consolidate_from_material =[];
                    context.paramsB5.consolidate_from_plant =[];
                    context.paramsB5.deletion_type = [];
                    context.inputDraftListB5.consolidate_to_plant = [];
                    context.inputDraftListB5.reason = [];
                    context.inputDraftListB5.note = [];

                    Api(context, draftList.getDetailMcrItem(data.mcr_item_code)).onSuccess(function(response) {    
                        context.inputDraftListB5.id = response.data.data[0].id;
                        context.inputDraftListB5.description_asal = response.data.data[0].specification;
                        context.inputDraftListB5.prc_consolidate_to = response.data.data[0].prc_consolidate_to;
                        context.inputDraftListB5.reason = response.data.data[0].reason;
                        context.inputDraftListB5.note = response.data.data[0].note;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.inputDraftListB5 = [];
                        }
                    }).call()
                    
                    Api(context, draftList.getMcrItemConsolidateFrom(data.mcr_item_code)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.paramsB5.consolidate_from_material[i] = response.data.data[i].material_number;
                            context.paramsB5.consolidate_from_plant[i] = response.data.data[i].plant_code;
                            context.paramsB5.deletion_type[i] = response.data.data[i].deletion_type;
                            context.selectMaterialNumber(i);
                        }                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.paramsB5.consolidate_from_material = [];
                            context.paramsB5.consolidate_from_plant = [];
                            context.paramsB5.deletion_type = [];
                        }
                    }).call()

                    Api(context, draftList.getMcrItemConsolidateTo(data.mcr_item_code)).onSuccess(function(response) {
                        response.data.data.forEach(function(item) {
                            context.inputDraftListB5.consolidate_to_plant.push(item.plant_code)
                        })                  
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.inputDraftListB5.consolidate_to_plant = [];
                        }
                    }).call()
                }
            },
            remove(data) {
                let context = this;
                context.confirmDialog(context.tt('confirm_delete')).then((result) => {
                    if (result.value) {
                        Api(context, draftList.deleteMcrItem(data.id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success'
                            });
                            context.get();
                        }).call();
                    }
                })
            },
            create(key) {
                if (key == 'MCR01') {
                    key = 'a1';
                } else if (key == 'MCR02') {
                    key = 'a2';
                } else if (key == 'MCR03') {
                    key = 'a3';
                } else if (key == 'MCR04') {
                    key = 'a4';
                }

                if (key == 'b5'){
                    this.paramsB5.consolidate_from_material[0] = '';
                    this.paramsB5.consolidate_from_plant[0] = '';
                    this.paramsB5.description_asal[0] = '';
                    this.paramsB5.deletion_type[0] = '';
                    this.inputDraftListB5.prc_consolidate_to = '';
                    this.inputDraftListB5.consolidate_to_plant = '';
                    this.inputDraftListB5.description_to = '';
                    this.inputDraftListB5.reason = '';
                    this.inputDraftListB5.note = '';
                    this.form.title = this.tt('add');
                    this.form.b5.show = true;
                } else if (key == 'b6') {
                    this.form.b6.show = true;
                }
            },
            addParamsB5() {
                this.paramsB5.consolidate_from_material.push('');
                this.paramsB5.consolidate_from_plant.push('');
                this.paramsB5.deletion_type.push('');
                this.paramsB5.description_asal.push('');
            },
            removeParamsB5(key) {
                this.paramsB5.consolidate_from_material.splice(key,1)
                this.paramsB5.consolidate_from_plant.splice(key,1)
                this.paramsB5.deletion_type.splice(key,1)
                this.paramsB5.description_asal.splice(key,1)
            },
            editMcrInformation() {
                this.formMcrInformation.add = false;
                this.formMcrInformation.show = true;
            }, 
            updateMcrInformation() {
                let context = this;     
                // context.errorMessage = null;      
                context.btnUpdate.onLoading = true;    
                Api(context, draftList.updateMcrSubject(context.mcrInformation.mcr_code, context.mcrInformation.token, context.mcrInformationEdit)).onSuccess(function(response) {
                    context.mcrInformation.subject = context.mcrInformationEdit.new_subject;
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success'
                    });
                }).onFinish(function() {
                    context.btnUpdate.onLoading = false;
                    context.formMcrInformation.show = false;
                }).call()        
            },
            saveB5(save) {
                let context = this;
                // alert(context.paramsB5.consolidate_from_plant)
                let data = {
                    mcr_code: context.mcr_code,
                    consolidate_from_material: [],
                    consolidate_from_plant: [],
                    deletion_type: [],
                    reason: context.inputDraftListB5.reason,
                    // specification: context.inputDraftListB5.description_to,
                }

                if (context.inputDraftListB5.reason != '' && context.inputDraftListB5.prc_consolidate_to != '') {
                    for (var i=0; i<context.paramsB5.consolidate_from_material.length; i++) {
                        if (context.paramsB5.consolidate_from_material[i] != '' &&
                            context.paramsB5.consolidate_from_plant[i] != '' &&
                            context.paramsB5.deletion_type[i] != '') {
                            data.consolidate_from_material[i] = context.paramsB5.consolidate_from_material[i];
                            data.consolidate_from_plant[i] = context.paramsB5.consolidate_from_plant[i];
                            data.deletion_type[i] = context.paramsB5.deletion_type[i];
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    } 

                    data.note = context.inputDraftListB5.note;
                    data.prc_consolidate_to = context.inputDraftListB5.prc_consolidate_to;
                    data.consolidate_to_plant = context.inputDraftListB5.consolidate_to_plant;

                    let api = '';
                    if (save == 'add') {
                        api = Api(context, draftList.createNewMcrItemNoFormData(data));
                    } else {
                        api = Api(context, draftList.updateMcrItemNoFormData(data, context.inputDraftListB5.id));
                    }
                    
                    api.onSuccess(function(response) {    
                        // context.draftList = response.data.data.data.data;
                        context.get();
                        context.$notify({
                            message: response.data.message,                  
                            type: 'success'
                        });
                        context.form.b5.show = false;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.draftList = [];
                        }
                    })
                    .call()
                } else {
                    context.$notify({
                        message: 'Please complete data!',                  
                        type: 'danger'
                    });
                    return false;
                }                
            },
            sendMcr(mcr_code) {
              this.btnSend.onLoading = true;
              let api = "";
              let context = this;
              api = Api(context, draftList.sendMcr(mcr_code));
                api.onSuccess(function(response) {
                  context.$notify({
                    message: response.data.message,
                    type: "success"
                  });
                  context.$router.push('/material/draft-list');
                })
                .onFinish(function() {
                  context.btnSend.onLoading = false;
                })
                .call();
            },
            toggleAccordion(show, type) {
                if (type == 'bdsA1') {
                    if (show == '') {
                        this.accordion.a1.bds.show = 'show'
                    } else {
                        this.accordion.a1.bds.show = ''
                    }
                } else if (type == 'mpnA1') {
                    if (show == '') {
                        this.accordion.a1.mpn.show = 'show'
                    } else {
                        this.accordion.a1.mpn.show = ''
                    }
                } else if (type == 'eqiA1') {
                    if (show == '') {
                        this.accordion.a1.eqi.show = 'show'
                    } else {
                        this.accordion.a1.eqi.show = ''
                    }
                } else if (type == 'adA1') {
                    if (show == '') {
                        this.accordion.a1.ad.show = 'show'
                    } else {
                        this.accordion.a1.ad.show = ''
                    }
                } else if (type == 'clA2') {
                    if (show == '') {
                        this.accordion.a2.cl.show = 'show'
                    } else {
                        this.accordion.a2.cl.show = ''
                    }
                } else if (type == 'plA2') {
                    if (show == '') {
                        this.accordion.a2.pl.show = 'show'
                    } else {
                        this.accordion.a2.pl.show = ''
                    }
                } else if (type == 'mpnA2') {
                    if (show == '') {
                        this.accordion.a2.mpn.show = 'show'
                    } else {
                        this.accordion.a2.mpn.show = ''
                    }
                } else if (type == 'eqiA2') {
                    if (show == '') {
                        this.accordion.a2.eqi.show = 'show'
                    } else {
                        this.accordion.a2.eqi.show = ''
                    }
                } else if (type == 'mpnA3') {
                    if (show == '') {
                        this.accordion.a3.mpn.show = 'show'
                    } else {
                        this.accordion.a3.mpn.show = ''
                    }
                } else if (type == 'eqiA3') {
                    if (show == '') {
                        this.accordion.a3.eqi.show = 'show'
                    } else {
                        this.accordion.a3.eqi.show = ''
                    }
                } else if (type == 'mpnA4') {
                    if (show == '') {
                        this.accordion.a4.mpn.show = 'show'
                    } else {
                        this.accordion.a4.mpn.show = ''
                    }
                } else if (type == 'eqiA4') {
                    if (show == '') {
                        this.accordion.a4.eqi.show = 'show'
                    } else {
                        this.accordion.a4.eqi.show = ''
                    }
                }
            },
            querySearchMaterialNumber(queryString, cb) {
                if (queryString.length > 2) {
                    var links   = this.links_material_number;
                    var results = queryString ? links.filter(this.createFilter(queryString)) : links;
                    cb(results);
                }
            },
            querySearchPlant(queryString, cb) {
                var links   = this.links_plant_code;
                var results = queryString ? links.filter(this.createFilter(queryString)) : links;
                cb(results);
            },
            selectMaterialNumber(key) {
                // this.data.search.material_number = item.value
                let context = this;
                Api(context, endUserSearchMaterial.partCharValueEndUser({material_number: context.paramsB5.consolidate_from_material[key]})).onSuccess(function(response) {
                    context.plantMaterial = response.data.data.part_plant;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.plantMaterial = [];
                    }
                })
                .call()
            },
            selectPlant(item) {
                this.paramsB5.consolidate_from_plant[this.plantKey] = item.code
            },
            selectPlantKey(key) {
                this.plantKey = key;
            },
            createFilter(queryString) {
                return (link) => {
                    return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
                };
            },
            tracking() {
                this.formTracking.show = false;
                this.formTracking.show = true;
            },
        }   
    };
</script>
<style></style>
